<template>
  <div class="result-publicity-detail container">
    <!-- 主体 -->
    <div class="main">
      <!-- 标题 -->
      <div class="title">
        <h1>{{form.publicityTitle}}</h1>
        <div class="release-date">
          <span>发布时间：</span>
          <span>{{form.releaseTime|filterDate}}</span>
        </div>
      </div>

      <!-- 内容 -->
      <div class="content">
        <!-- 公示信息 -->
        <publicity-info :form="form"></publicity-info>

        <span style="color: #000" v-if="form.electronic * 1 === 0 && query.type != 4">公示内容：</span>
        <div class="nonElectronicContext" v-if="form.electronic * 1 === 0 && query.type != 4" v-html="form.nonElectronicContext"></div>

        <div v-if="form.isEnter*1 === 1">
          <span style="color: #000">公示内容：</span>
          <div class="nonElectronicContext" v-html="form.enterRemark"></div>
        </div>
        <!-- 中标候选人信息/中标供应商信息 -->
        <template
          v-if="(query.type==1||(query.type==5&&query.correctType==1)||query.type==3||(query.type==2&&query.reviewResult<3)) && form.electronic * 1 !== 0 && form.isEnter*1 === 0"
        >
          <!-- 循环候选人信息（工程建设的评标结果公示要显示3个候选人） -->
          <template v-for="candidateInfo in form.tbBidCandidateInfos">
            <!-- 候选人信息 -->
            <candidate-info
              :key="candidateInfo.id"
              v-if="query.isGov==1"
              :candidateInfo="candidateInfo"
              :form="form"
              :isUseWinningTemplate="form.isUseWinningTemplate"
            ></candidate-info>
          </template>

          <!-- 供应商信息 -->
          <div v-for="item in form.tbSupplierInfos" :key="item.id">
            <supplier-info v-if="query.isGov==2" :supplierInfo="item" :form="form"></supplier-info>
          </div>
        </template>

        <!-- 流标情况 -->
        <failure-bid
          v-if="query.type==4||(query.type==5&&query.correctType==2)||(query.type==2&&query.reviewResult==3)"
          :form="form"
        ></failure-bid>

        <!-- 其他信息 -->
        <else-info :form="form"></else-info>
      </div>

      <FileList :fileList="form.filePath"></FileList>

    </div>
  </div>
</template>

<script>
import tradeInfoApi from "@/api/tradeInfo";
import PublicityInfo from "@/components/ResultPublicity/PublicityInfo.vue";
import CandidateInfo from "@/components/ResultPublicity/CandidateInfo.vue";
import SupplierInfo from "@/components/ResultPublicity/SupplierInfo.vue";
import FailureBid from "@/components/ResultPublicity/FailureBid.vue";
import ElseInfo from "@/components/ResultPublicity/ElseInfo.vue";
import FileList from '@/components/FileList/index.vue'

export default {
  components: {
    PublicityInfo,
    CandidateInfo,
    SupplierInfo,
    FailureBid,
    ElseInfo,
    FileList
  },
  async created() {
    // 获取地址栏传过来的参数
    this.query = this.$route.query;
    // 根据参数获取详情
    let { data } = await tradeInfoApi.findTradeDetail(this.query);
    this.form = data;
    this.form.filePath = JSON.parse(this.form.filePath);
  },
  data() {
    return {
      //参数说明（id:公示id，type:公示类型，isGov:是否政府，correctType:修正类型）
      query: { id: "", type: "", isGov: "", correctType: "" },
      form: {
        tbBidCandidateInfos: [{}], //中标候选人
        tbSupplierInfos: [{}], //中标供应商
        filePath: [],
        tbExpertInfos: [] //专家名单
      }
    };
  },

  filters: {
    // 时间
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    filterDate (val) {
      return moment(val).format("YYYY-MM-DD");
    }
  }
};
</script>

<style lang="less" scoped>
.result-publicity-detail {
  // 主体
  .main {
    // 标题
    .title {
      text-align: center;
      padding: 20px 0;
      border-bottom: 1px dashed #ECECF1;
      h1 {
        color: #20201E;
        font-size: 22px;
        margin-bottom: 10px;
        font-weight: bold;
      }
      .release-date {
        color: #999999;
        font-size: 14px;
      }
    }

    // 内容
    .content {
      color: #666666;
      font-size: 14px;
      line-height: 30px;
      // border: 1px solid #deeaf3;
      padding: 20px;
      margin-bottom: 20px;
      /deep/ table[border]:not([border="0"]):not([style*=border-style]) td, table[border]:not([border="0"]):not([style*=border-style]) th {
        border-color: #ccc;
        border-style: solid;
        border-width: 1px;
      }
    }
  }
  .nonElectronicContext{
    width: 100%;
    padding: 10px;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 4px;
    overflow: auto;
  }
}
</style>