<template>
  <div class="failure-bid">
    <h3 class="title">流标情况：</h3>
    <div class="content">
      <pre style="white-space: break-spaces">
        {{form.flowBidReason}}
      </pre>
    </div>
  </div>
</template>

<script>
export default {
  props: ["form"]
};
</script>

<style lang="less" scoped>
.failure-bid {
  margin-bottom: 20px;
  .title {
    color: black;
  }
}
</style>