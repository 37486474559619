<template>
  <!-- 候选人信息 -->
  <div class="candidate-info">
    <!-- 标题 -->
    <!-- 评标结果公示/修正 标题 -->
    <!-- <h3 class="candidate-title" v-if="$route.query.type==1||$route.query.type==5">中标候选人：</h3> -->
    <!-- 中标结果公示标题 -->
    <h3 class="candidate-title" v-if="$route.query.type==3">中标结果公示：</h3>

    <!-- 下面的每一项内容 -->
    <div class="item">
      <!-- 评标结果的候选人/修正 -->
      <span class="first-candidate" v-if="$route.query.type==1||$route.query.type==5">{{filterPersonRank(candidateInfo.personRank)}}：</span>
      <!-- 中标结果的候选人 -->
      <span class="first-candidate" v-if="$route.query.type==3">中标人名称：</span>

      <!-- 候选人名称 -->
      <span>{{candidateInfo.candidateName|filterCandidateName}}</span>
    </div>
    <div class="item">
      <span>得分：</span>
      <span>{{candidateInfo.totalScore}}</span>
    </div>
    <div v-if="isUseWinningTemplate == 0">
      <span>公示内容：</span>
      <span>{{candidateInfo.remark}}</span>
    </div>
    <div v-else>
      <div class="item">
        <span>单位代码：</span>
        <span>{{candidateInfo.usocialCreditCode|filterUsocialCreditCode}}</span>
      </div>
      <div class="item" v-if="form.isConsortium==1">
        <span>联合体投标单位：</span>
        <span>{{candidateInfo.candidateName|filterConsortium}}</span>
      </div>
      <div class="item" v-if="form.isConsortium==1">
        <span>联合体投标单位代码：</span>
        <span>{{candidateInfo.usocialCreditCode|filterConsortiumCode}}</span>
      </div>
      <div class="item">
        <span>是否拟中标人：</span>
        <span>{{candidateInfo.isSuccessBidder|filterIsSuccessBidder}}</span>
      </div>
      <div class="item">
        <span>评标排名：</span>
        <span>{{candidateInfo.candidateRank}}</span>
      </div>
      <div class="item">
        <span>报价：</span>
        <span>{{candidateInfo.bidPrice}}</span>
      </div>
      <div class="item">
        <span>项目负责人：</span>
        <span>{{candidateInfo.projectLeader}}</span>
      </div>
      <div class="item">
        <span>项目负责人相关证书名称和编号：</span>
        <span>{{candidateInfo.certificateNum}}</span>
      </div>
      <div class="item">
        <span>项目负责人业绩：</span>
        <span>{{candidateInfo.projectAchievement}}</span>
      </div>
      <div class="item">
        <span>工期（交货期）：</span>
        <span>{{candidateInfo.constructionPeriod}}</span>
      </div>
      <div class="item">
        <span>质量：</span>
        <span>{{candidateInfo.quality}}</span>
      </div>
      <div class="item">
        <span>投标人资质等级：</span>
        <span>{{candidateInfo.enterpriseQualification}}</span>
      </div>
      <div class="item">
        <span>信誉要求：</span>
        <span>{{candidateInfo.reputationRequire}}</span>
      </div>
      <div class="item">
        <span>企业业绩：</span>
        <span>{{candidateInfo.enterprisePerform}}</span>
      </div>
      <div class="item">
        <span>安全生产许可证：</span>
        <span>{{candidateInfo.safetyPermit}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["candidateInfo", "form", "isUseWinningTemplate"],
  methods: {
    // 中标候选人排名
    filterPersonRank(val) {
      // if (val == 1) return '第一中标候选人'
      // if (val == 2) return '第二中标候选人'
      // if (val == 3) return '第三中标候选人'
      // return val
      let str = this.form.bidMethod*1 === 1 || this.form.bidMethod*1 === 2 ? '中标候选人' : '成交候选人'
      if (val == 1) {
        return "第一" + str;
      } else if (val == 2) {
        return "第二" + str;
      } else if (val == 3) {
        return "第三" + str;
      } else if (val == 4) {
        return "第四" + str;
      } else if (val == 5) {
        return "第五" + str;
      } 
      return val
    },
  },
  filters: {
    // 中标候选人
    filterCandidateName(val) {
      if (val && val.includes("||")) {
        return val.substring(0, val.indexOf("||"));
      } else {
        return val;
      }
    },
    // 中标候选人单位代码
    filterUsocialCreditCode(val) {
      if (val && val.includes("||")) {
        return val.substring(0, val.indexOf("||"));
      } else {
        return val;
      }
    },
    // 联合体投标单位
    filterConsortium(val) {
      if (val && val.includes("||")) {
        return val.substring(val.indexOf("||") + 2);
      } else {
        return "无";
      }
    },
    // 联合体投标单位代码
    filterConsortiumCode(val) {
      if (val && val.includes("||")) {
        return val.substring(val.indexOf("||") + 2);
      } else {
        return "无";
      }
    }, // 是否拟中标人：
    filterIsSuccessBidder(val) {
      if (val == 1) return "是";
      if (val == 0) return "否";
    }
  }
};
</script>

<style lang="less" scoped>
.candidate-info {
  margin-bottom: 20px;
  .candidate-title {
    color: #000000;
  }
  .item {
    .first-candidate {
      color: #000000;
    }
  }
}
</style>