<template>
  <!-- 公示的其他信息 -->
  <div class="else-info">
    <!-- 专家名单 -->
    <div class="item" v-if="$route.query.type==3 && form.electronic == '1'">
      <span>评标委员会成员名单：</span>
      <el-table :data="form.tbExpertInfos" border size="small" style="width:500px">
        <el-table-column prop="no" label="序号"></el-table-column>
        <el-table-column prop="expertName" label="评委姓名"></el-table-column>
      </el-table>
    </div>

    <!-- 备注 -->
    <div class="item">
      <span>备注：</span>
      <span>{{form.remark}}</span>
    </div>

    <!-- 非标情况 -->
    <div class="item" v-if="form.abandonedBid">
      <span>{{form.bidMethod === 1 || form.bidMethod === 2 ? '评标情况：' : '废标情况：'}}</span>
      <span>{{form.abandonedBid}}</span>
    </div>
  </div>
    <!-- 附件 -->
    <!-- <div class="file">
      <p>
        <i class="iconfont icon-fujian"></i>
        <span>招标人审核意见附件：</span>
      </p>
      <p v-for="(v,i) in form.filePath" :key="i">
        <a :href="baseURL+v" target="_blank">{{ v.substring( v.lastIndexOf('/')+1 ) }}</a>
      </p>
    </div>
  </div> -->
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["form"],
  computed: {
    ...mapState(["baseURL", "bidderURL"])
  }
};
</script>

<style lang="less" scoped>
.else-info {
  .item {
    span:first-child {
      color: #000000;
    }
  }
  .file {
    p:first-child {
      color: #000000;
    }
    a {
      color: #3660d9;
    }
  }
}
</style>