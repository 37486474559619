<template>
  <!-- 供应商信息 -->
  <div class="candidate-info">
    <!-- <h3 class="candidate-title">中标供应商信息：</h3> -->
    <div class="item">
      <template v-if="form.isUseWinningTemplate == 0">
        <span class="first-candidate" v-if="(form.tbSupplierInfos.length > 1)"> {{filterPersonRank(supplierInfo.personRank)}} </span>
        <span class="first-candidate" v-if="(form.tbSupplierInfos.length === 1)">中标人名称：</span>
      </template>
      <span class="first-candidate" v-else>第一中标人</span>
      <span>{{ supplierInfo.supplierName|filterSupplierName}}</span>
    </div>
    <div class="item" v-if="form.isUseWinningTemplate == 0">
      <span>公示内容：</span>
      <span>{{supplierInfo.remark}}</span>
    </div>
    <div v-else>
      <div class="item">
        <span>单位代码：</span>
        <span>{{supplierInfo.usocialCreditCode|filterUsocialCreditCode}}</span>
      </div>
      <div class="item" v-if="form.isConsortium==1">
        <span>联合体投标单位：</span>
        <span>{{supplierInfo.supplierName|filterConsortium}}</span>
      </div>
      <div class="item" v-if="form.isConsortium==1">
        <span>联合体投标单位代码：</span>
        <span>{{supplierInfo.usocialCreditCode|filterConsortiumCode}}</span>
      </div>
      <div class="item">
        <span>供应商排名：</span>
        <span>{{supplierInfo.supplierRank}}</span>
      </div>
      <div class="item">
        <span>供应商地址：</span>
        <span>{{supplierInfo.supplierAddress}}</span>
      </div>
      <div class="item">
        <span>采购明细名称：</span>
        <span>{{supplierInfo.purchaseDetailName}}</span>
      </div>
      <div class="item">
        <span>数量：</span>
        <span>{{supplierInfo.quantity}}</span>
      </div>
      <div class="item">
        <span>采购预算：</span>
        <span>{{supplierInfo.purchaseBudget}}</span>
      </div>
      <div class="item">
        <span>中标金额：</span>
        <span>{{supplierInfo.winBidPrice}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["supplierInfo", "form"],
  methods: {
    // 中标候选人排名
    filterPersonRank(val) {
      // if (val == 1) return '第一候选供应商:'
      // if (val == 2) return '第二候选供应商:'
      // if (val == 3) return '第三候选供应商:'
      let str = this.form.bidMethod*1 === 1 || this.form.bidMethod*1 === 2 ? '中标候选人' : '成交候选人'
      if (val == 1) {
        return "第一" + str;
      } else if (val == 2) {
        return "第二" + str;
      } else if (val == 3) {
        return "第三" + str;
      } else if (val == 4) {
        return "第四" + str;
      } else if (val == 5) {
        return "第五" + str;
      } 
      return val
    },
  },
  filters: {
    // filterPersonRank(val) {
    //   // if (val == 1) return '第一候选供应商:'
    //   // if (val == 2) return '第二候选供应商:'
    //   // if (val == 3) return '第三候选供应商:'
    //   let str = this.form.bidMethod*1 === 1 || this.form.bidMethod*1 === 2 ? '中标候选人' : '成交候选人'
    //   if (val == 1) {
    //     return "第一" + str;
    //   } else if (val == 2) {
    //     return "第二" + str;
    //   } else if (val == 3) {
    //     return "第三" + str;
    //   } 
    // },
    // 中标供应商
    filterSupplierName(val) {
      if (val && val.includes("||")) {
        return val.substring(0, val.indexOf("||"));
      } else {
        return val;
      }
    },
    // 中标供应商单位代码
    filterUsocialCreditCode(val) {
      if (val && val.includes("||")) {
        return val.substring(0, val.indexOf("||"));
      } else {
        return val;
      }
    },
    // 联合体投标单位
    filterConsortium(val) {
      if (val && val.includes("||")) {
        return val.substring(val.indexOf("||") + 2);
      } else {
        return "无";
      }
    },
    // 联合体投标单位代码
    filterConsortiumCode(val) {
      if (val && val.includes("||")) {
        return val.substring(val.indexOf("||") + 2);
      } else {
        return "无";
      }
    }
  }
};
</script>

<style lang="less" scoped>
.candidate-info {
  margin-bottom: 20px;
  .candidate-title {
    color: #000000;
  }
  .item {
    .first-candidate {
      color: #000000;
    }
  }
}
</style>